export default {
  /**
   * @return {string}
   */
  DO_NOTHING (state) {
    return state + 'azerty';
  },
  ADD_ITEM (state, item) {
    state.rendezvous.unshift(item)
  },
  SET_LIST (state, payload) {
    state.rendezvous = payload
  },
  SET_DOCTOR (state, payload) {
    state.doctor = payload
  },
  SET_CENTRES (state, payloads) {
    state.rendezvous = payloads
  },
  UPDATE_ITEM (state, payload) {
    const dataIndex = state.rendezvous.findIndex((p) => p.slug === payload.slug);
    Object.assign(state.rendezvous[dataIndex], payload)
  },
  REMOVE_ITEM (state, itemId) {
    const ItemIndex = state.rendezvous.findIndex((p) => p.slug === itemId);
    state.rendezvous.splice(ItemIndex, 1)
  }
}
