<template>
  <div>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        size="lg"
        id="setDataModal"
        ref="setDataModal"
        :title="
          Object.entries(this.modaldata.data).length === 0
            ? 'Nouveau'
            : 'Modification'
        "
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Demande de rendez-vous
          <hr />
        </h3>

        <rendezvous-details
          v-bind:data="this.modaldata.data"
        ></rendezvous-details>
        <hr />
        <!-- submit and reset -->
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="closeModal('edit')"
            >
              Fermer
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="ml-1"
              style="float: right"
              
              @click="buildValidateModal"
            >
              <span>Valider</span>
            </b-button>
            <b-dropdown
              style="float: right"
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Options"
              variant="primary"
            >
              <b-dropdown-item @click="buildAssignModal">Assigner Medécin</b-dropdown-item>
              <b-dropdown-item @click="buildReprogramModal" v-if="this.modaldata.data.doctor !== null"
                >Reprogrammer</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
      </b-modal>
      <!-- ASSIGN DOCTOR -->
      <b-modal
        id="assignDoctorModal"
        ref="assignDoctorModal"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Assigner un medecin à la demande
          <hr />
          <small class="text-danger">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="assign">
          <!-- DORTORS -->
          <b-form-group label="* Sélectionner un medécin" label-for="doctor">
            <v-select
              id="doctor"
              label="name"
              value="slug"
              v-model="assigndata.form.doctor"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Sélectionner un medécin"
              :options="buildDoctors"
            />
          </b-form-group>
          <hr />
          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('assign')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- REPROGRAMMATION -->
      <b-modal
        id="reprogramModal"
        ref="reprogramModal"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Assigner un medecin à la demande
          <hr />
          <small class="text-danger">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="reprogram">
          <!-- DATE -->
          <b-form-group label="* Sélectionner une date" label-for="date">
            <b-form-datepicker
              label-no-date-selected="Aucune date sélectionnée"
              label-help="Utiliser les touches directionnelles pour naviguer"
              id="date"
              :min="reprogramdata.form.min"
              v-model="reprogramdata.form.date"
              menu-class="w-100"
              calendar-width="100%"
              class="mb-2"
              placeholder="Sélectionner une date"
            />
          </b-form-group>
          <!-- HOUR -->
          <b-form-group label="* Sélectionner une heure" label-for="hour">
            <cleave
              id="hour"
              v-model="reprogramdata.form.hour"
              class="form-control"
              :raw="false"
              :options="reprogramdata.options.time"
              placeholder="hh:mm"
            />
          </b-form-group>
          <hr />
          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('reprogram')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- VALIDATE -->
      <b-modal
        id="validateModal"
        ref="validateModal"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Assigner un medecin à la demande
          <hr />
          <small class="text-danger">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="validate">
          <!-- DECISION -->
          <b-form-group label="* Décision" label-for="decision">
            <v-select
              id="decision"
              label="text"
              value="key"
              v-model="validatedata.form.requestStat"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Sélectionner une décision"
              :options="validatedata.decision"
            />
          </b-form-group>
          <!-- DENIAL REASON -->
          <div v-if="validatedata.form.requestStat">
            <b-form-group v-if="validatedata.form.requestStat.key === 'denied'" label="* Motif du rejet" label-for="reason">
              <b-form-input
                v-model="validatedata.form.denialReason"
                id="reason"
                placeholder="Motif"
                required
              />
            </b-form-group>
          </div>
          
          <hr />
          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('validate')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="tabledata.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tabledata.pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tabledata.filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="buildData"
        responsive
        :fields="tabledata.columns"
        primary-key="id"
        :current-page="tabledata.currentPage"
        :per-page="tabledata.perPage"
        :filter="tabledata.filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
      >
        <!-- Column: post Status -->
        <template #cell(requestStat)="data">
          <b-avatar
            :id="`post-row-${data.item.slug}`"
            size="32"
            :variant="`light-${
              postStatusVariantAndIcon(data.item.requestStat).variant
            }`"
          >
            <feather-icon
              :icon="postStatusVariantAndIcon(data.item.requestStat).icon"
            />
          </b-avatar>
          {{ postStatusVariantAndIcon(data.item.requestStat).text }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">

            <feather-icon
              :id="`post-row-${data.item.slug}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1"
              @click.stop="buildEditModal(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="tabledata.currentPage"
              :total-rows="tabledata.totalRows"
              :per-page="tabledata.perPage"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BTooltip,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormTags,
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ModuleRendezvousMedical from "@/store/modules/medical/rendezvous/rendezvous.module";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import * as payloads from "./payloads";
import RendezvousDetails from "./rendezvous-details.vue";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BFormTags,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormDatepicker,

    vSelect,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    RendezvousDetails,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // 15th two months prior
    const minDate = new Date(today)

    return {
      isLoading: false,
      singleData: [], // For single view mode
      locale: "fr",
      isModalActive: false,
      modalData: [],
      // TABLE PARAMS
      tabledata: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, 30],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        columns: [
          {
            key: "user",
            label: "Demandeur",
            sortable: true,
            formatter: (val) => (val ? val.lastname +' '+ val.firstname : ''),
          },
          {
            key: "speciality",
            label: "Spécialité",
            sortable: true,
            formatter: (val) => `${val}`,
          },
          {
            key: "centre",
            label: "Lieu",
            sortable: true,
            formatter: (val) => `${val.name}`,
          },
          {
            key: "requestStat",
            label: "Statut",
            sortable: true,
          },
          { key: "actions" },
        ],
        searchTerm: "",
      },
      // Data NEW/EDIT Modal
      assigndata: {
        form: {doctor: ''}
      },
      reprogramdata: {
        form: {
          date: '',
          hour: '',
          min: minDate,
        },
        options: {
          time: {
            time: true,
            timePattern: ['h', 'm'],
          }
        }
      },
      validatedata: {
        form: {requestStat: '', denialReason: ''},
        decision: payloads.decision
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      },
    };
  },
  created() {
    if (!ModuleRendezvousMedical.isRegistered) {
      this.$store.registerModule("ModuleRendezvousMedical", ModuleRendezvousMedical);
      ModuleRendezvousMedical.isRegistered = true;
    }
    this.$store
      .dispatch("ModuleRendezvousMedical/fetch", this.buildCurrentPath().path)
      .then((data) => {})
      .catch((e) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erreur`,
            icon: "WarningIcon",
            variant: "danger",
            text: `Impossible de charger la liste des données. Merci de réessayer plutard.`,
          },
        });
      });
      
  },
  computed: {
    buildData() {
      const data = this.$store.state.ModuleRendezvousMedical.rendezvous;
      this.tabledata.totalRows = data === undefined ? 0 : data.length;

      return data === undefined ? [] : data;
    },
    buildDoctors () {
        return this.$store.state.ModuleRendezvousMedical.doctor
    },
    buildCentres () {
        return this.$store.state.ModuleRendezvousMedical.centre
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    buildCurrentPath() {
      let path = this.$router.history.current.path;
      let getLastItem = (thePath) =>
        thePath.substring(thePath.lastIndexOf("/") + 1);
      path = getLastItem(path);

      return {
        path: path,
        route: `apps-medical-${path}`,
      };
    },
    emptyFileInput() {
      this.formdata.file = null;
    },
    closeModal(object) {
      if (object === "assign") {
        this.$refs["assignDoctorModal"].hide();
      } else if (object === "edit") {
        this.$refs["setDataModal"].hide();
      } else if (object === "reprogram") {
        this.$refs["reprogramModal"].hide();
      } else if (object === "validate") {
        this.$refs["validateModal"].hide();
      } else {
        
      }
    },
    openSaveDialog() {
      this.$refs["setDataModal"].show();
    },
    openImportDialog() {
      this.$refs["importModal"].show();
    },
    swalError(title, message, type) {
      this.$swal({
        title: title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    openDialog(data) {
      this.$refs.singleDataModal.show();
      this.singleData = data;
    },

    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: Object.assign({}, this.$route.query, { pid: id }),
      });
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Voulez-vous supprimer cet enregistrement ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteData();
          } else {
            this.revertUrl();
          }
        });
    },
    deleteData() {
      let id = this.$route.query.pid;
      if (id !== "" || id !== null) {
        this.$store
          .dispatch("ModuleRendezvousMedical/remove", id)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Action Réussie`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Enregistrement supprimé avec succès!`,
              },
            });
            this.revertUrl();
          })
          .catch((error) => {
            if (typeof error === "object") {
              for (let err in error) {
                this.swalError("Erreur", err, "error");
              }
            } else {
              this.$vs.notify({
                title: "Erreur",
                text: error,
                color: "danger",
                icon: "error",
              });
            }
            this.revertUrl();
          });
      }
    },
    revertUrl() {
      this.$router.replace({ name: this.buildCurrentPath().route });
    },
    // END ---
    // MODAL FORM TOOL --------------
    resetValues() {
      this.formdata.slug = null;
      this.formdata.title = "";
      this.formdata.category = [];
      this.formdata.content = "";
      this.formdata.file = null;
      this.modaldata.data = {};

      console.log("INPUT RESET SUCCEED...");
    },
    
    assign(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.assigndata.form.doctor === "") {
        this.swalError("Erreur", this.buildRequiredMessage("medécin"), "error");
      } else {
        let global = {
          slug: this.modaldata.data.slug,
          doctor: this.assigndata.form.doctor.slug
        };

        this.isLoading = true;

        this.$store
            .dispatch("ModuleRendezvousMedical/assign", global)
            .then((doc) => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Medécin assigné avec succès!`,
                },
              });
              this.modaldata.data = doc
              this.closeModal('assign');
            })
            .catch((error) => {
              this.isLoading = false;
              console.log(error);

              this.swalError("Erreur", error.message, "error");
            });
      }
    },
    reprogram(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.reprogramdata.form.date === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Jour"), "error");
      } else if (this.reprogramdata.form.hour === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Heure"), "error");
      } else {
        
        let global = {
          slug: this.modaldata.data.slug,
          newDay: this.reprogramdata.form.date,
          newHour: this.reprogramdata.form.hour,
          specialityData: {
            days: this.modaldata.data.doctor.days,
            hours: this.modaldata.data.doctor.hours,
          }
        };

        this.isLoading = true;

        this.$store
            .dispatch("ModuleRendezvousMedical/reprogram", global)
            .then((doc) => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Demande reprogrammée avec succès!`,
                },
              });
              this.modaldata.data = doc
              this.closeModal('reprogram');
            })
            .catch((error) => {
              this.isLoading = false;
              let errorLog = error.data;
              if(typeof data === 'object') {
                this.swalError("Erreur", errorLog[0], "error");
              }

              this.swalError("Erreur", errorLog.join(' - '), "error");
            });
      }
    },
    validate(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.reprogramdata.form.requestStat === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Décision"), "error");
      } else if (this.reprogramdata.form.denialReason === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Motif"), "error");
      } else {
        
        let global = {
          slug: this.modaldata.data.slug,
          requestStat: this.validatedata.form.requestStat.key,
          denialReason: this.validatedata.form.denialReason,
        };

        this.isLoading = true;

        this.$store
            .dispatch("ModuleRendezvousMedical/validate", global)
            .then((doc) => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Action Réussie`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Demande validé avec succès! Le demandeur recevra une notification de RDV`,
                },
              });
              this.modaldata.data = doc
              this.closeModal('validate');
            })
            .catch((error) => {
              this.isLoading = false;
              let errorLog = error.data;
              if(typeof data === 'object') {
                this.swalError("Erreur", errorLog[0], "error");
              }
              console.log(errorLog)
              this.swalError("Erreur", errorLog, "error");
            });
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`;
    },
    buildEditModal(data) {
      this.modaldata.data = data;
      this.modaldata.isModalActive = true;

      this.$refs["setDataModal"].show();
    },
    // ASSIGN MODAL OPENNER
    buildAssignModal() {
      this.$store.dispatch("ModuleRendezvousMedical/fetchDoctor", this.modaldata.data.speciality)
      this.$refs["assignDoctorModal"].show();
    },
    buildReprogramModal() {
      this.$refs["reprogramModal"].show();
    },
    buildValidateModal() {
      this.$refs["validateModal"].show();
    },
    buildEditModalTMP(data) {
      this.modaldata.data = data;
      this.modaldata.isModalActive = true;

      if (!data) return;
      if (Object.entries(data).length === 0) {
        this.resetValues();
      } else {
        const { slug, title, category, categoryName, content } = JSON.parse(
          JSON.stringify(data)
        );

        this.formdata.slug = slug;
        this.formdata.title = title;
        this.formdata.category = {
          key: category,
          text: categoryName,
        };
        this.formdata.content = content;
      }

      this.$refs["setDataModal"].show();
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app;
    const postStatusVariantAndIcon = (requestStat) => {
      if (requestStat === "pending")
        return { variant: "warning", icon: "PieChartIcon", text: "En attente" };
      if (requestStat === "allowed")
        return { variant: "success", icon: "CheckCircleIcon", text: "Accepté" };
      if (requestStat === "denied")
        return { variant: "danger", icon: "XIcon", text: "Refusé" };
      if (requestStat === "ended")
        return { variant: "info", icon: "CheckCircleIcon", text: "Terminé" };
      return { variant: "primary", icon: "XIcon" };
    };

    return {
      appName,
      appLogoImage,
      appDesc,
      postStatusVariantAndIcon,
    };
  },
};
</script>
