import state from './rendezvous.state.js'
import mutations from './rendezvous.mutations.js'
import actions from './rendezvous.actions.js'
import getters from './rendezvous.getters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

