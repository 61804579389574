<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-list-group flush v-if="data">
          <b-list-group-item class="text-primary" disabled
            >Détails demande <b></b
          ></b-list-group-item>
          <b-list-group-item
            >Motif: <b>{{ data.reason }}</b></b-list-group-item
          >
          <b-list-group-item v-if="data.centre"
            >Lieu: <b>{{ data.centre.name }}</b></b-list-group-item
          >
          <b-list-group-item
            >Jour & Heure:
            <b
              >{{ this.$coddyger.capitalizeFirstLetter(data.days) }} à
              {{ data.hours }}</b
            ></b-list-group-item
          >
          <b-list-group-item v-if="data.centre"
            >Adresse: <b>{{ data.centre.address }}</b></b-list-group-item
          >
          <b-list-group-item
            >Statut:
            <b-avatar
              size="32"
              :variant="`light-${
                postStatusVariantAndIcon(data.requestStat).variant
              }`"
            >
              <feather-icon
                :icon="postStatusVariantAndIcon(data.requestStat).icon"
              />
            </b-avatar>
            <b>{{ this.postStatusVariantAndIcon(data.requestStat).text }}</b>
          </b-list-group-item>
          <b-list-group-item
            >Date de demande:
            <b>{{
              this.$coddyger.cdgDateFormat(data.createdAt, true)
            }}</b></b-list-group-item
          >
        </b-list-group>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col lg="12">
            <b-list-group flush v-if="data">
              <b-list-group-item class="text-primary" disabled
                >Détails du demandeur <b></b
              ></b-list-group-item>
              <b-list-group-item v-if="data.user"
                >Nom:
                <b
                  >{{ data.user.lastname }} {{ data.user.firstname }}</b
                ></b-list-group-item
              >
              <b-list-group-item v-if="data.user"
                >Poste:
                <b>{{
                  this.$coddyger.capitalizeFirstLetter(data.user.jobTitle)
                }}</b></b-list-group-item
              >
              <b-list-group-item
                >Contact: <b>{{ data.userContact }}</b></b-list-group-item
              >
            </b-list-group>
          </b-col>
          <b-col lg="12">
              <b-list-group flush v-if="data">
              <b-list-group-item class="text-primary" disabled
                >Détails du medécin <b></b
              ></b-list-group-item>
              <b-list-group-item class="text-danger" v-if="!data.doctor"
                >Assigner un medécin à cette demande</b-list-group-item
              >
              <b-list-group-item v-if="data.doctor"
                >Nom:
                <b
                  >{{ data.doctor.name }}</b
                ></b-list-group-item
              >
              <b-list-group-item v-if="data.doctor"
                >Jour(s) de reception:
                <b>{{
                  data.doctor.days.join(' - ')
                }}</b></b-list-group-item
              >
              <b-list-group-item v-if="data.doctor"
                >Heure(s) de reception:
                <b>{{
                  data.doctor.hours.join(' - ')
                }}</b></b-list-group-item
              >
              <b-list-group-item v-if="data.doctor"
                >Spécialité du médecin: <b>{{ data.doctor.speciality }}</b></b-list-group-item
              >
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    data: {
        type: Object,
        default: () => {},
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const postStatusVariantAndIcon = (requestStat) => {
      if (requestStat === "pending")
        return { variant: "warning", icon: "PieChartIcon", text: "En attente" };
      if (requestStat === "allowed")
        return { variant: "success", icon: "CheckCircleIcon", text: "Accepté" };
      if (requestStat === "denied")
        return { variant: "danger", icon: "XIcon", text: "Refusé" };
      if (requestStat === "ended")
        return { variant: "info", icon: "CheckCircleIcon", text: "Terminé" };
      return { variant: "primary", icon: "XIcon" };
    };

    return {
      postStatusVariantAndIcon,
    };
  },
});
</script>

